import { CertificateIssuerType } from './CertificateIssuerType.model';
import { BaseModel, StrictBaseModelPart, EnumProperty, hasUnionKeys } from '@dev-stream/utils';

export class ExistingAccountInfoModel extends BaseModel<ExistingAccountInfoModel> {
    protected BASE_TYPE = ExistingAccountInfoModel;
    @EnumProperty(() => CertificateIssuerType)
    'ExistingAccountIssuerType'!: CertificateIssuerType | null;
    'ExistingAccountEmail'!: string | null;
    'ExistingAccountPhoneNumber'!: string | null;
    'ExistingAccountCompanyName'!: string | null;
    'ExistingAccountCompanyBIN'!: string | null;
    'ExistingAccountDirectorFullName'!: string | null;

    public GetDefaultValue(): StrictBaseModelPart<ExistingAccountInfoModel> {
        return {
            ExistingAccountIssuerType: null,
            ExistingAccountEmail: null,
            ExistingAccountPhoneNumber: null,
            ExistingAccountCompanyName: null,
            ExistingAccountCompanyBIN: null,
            ExistingAccountDirectorFullName: null,
        };
    }

    public static isExistingAccountInfoModel(o: any): o is ExistingAccountInfoModel {
        return (
            o instanceof ExistingAccountInfoModel ||
            hasUnionKeys(o, [
                'ExistingAccountIssuerType',
                'ExistingAccountEmail',
                'ExistingAccountPhoneNumber',
                'ExistingAccountCompanyName',
                'ExistingAccountCompanyBIN',
                'ExistingAccountDirectorFullName',
            ])
        );
    }
}
