import { Injectable } from '@angular/core';
import { mapLeft, mapRight } from '@dev-stream/utils';
import { ApplicationUserTwoFactorAuthOption } from '@idocs/api/identity/models/ApplicationUserTwoFactorAuthOption.model';
import { LoginByUserCertificateCommand } from '@idocs/api/identity/models/LoginByUserCertificateCommand.model';
import { LoginByUserIINCommand } from '@idocs/api/identity/models/LoginByUserIINCommand.model';
import { Verify2FACodeCommand } from '@idocs/api/identity/models/Verify2FACodeCommand.model';
import { AccountApiService } from '@idocs/api/identity/services/Account.api-service';
import * as moment from 'moment';
import { mapToApiFailureResponseModel } from '@idocs/api';
import { RegisterCommand } from '@idocs/api/identity/models/RegisterCommand.model';
import { IdentityConnectorServer } from '@idocskz/identity-connector';
import {
    apiResponseErrorHandler,
    SharedAccountModel,
} from '@idocs/shared-logic';
import { BaseAuthService } from '@idocs/shared-logic/shared-auth/shared-auth.service';
import { Router } from '@angular/router';
import { TokenResult } from '@idocs/api/identity/models/TokenResult.model';
import { showNotification } from '@idocs/shared-ui/notification';
import { CertificateIssuerType } from '@idocs/api/identity/models/CertificateIssuerType.model';

export type AuthServiceSignInResult = {
    TwoFANeeded: boolean;
    HasMultipleAccounts: boolean;
};

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseAuthService {
    constructor(
        private accountApi: AccountApiService,
        private identityConnectorServer: IdentityConnectorServer<SharedAccountModel>,
        private router: Router
    ) {
        super();
    }

    authenticate() {
        this.router.navigate(['/authenticate']);
    }

    get tokenResult() {
        const account = this.identityConnectorServer.selectedAccount;
        return account?.tokenResult;
    }

    updateTokenResult(tokenResult: TokenResult) {
        const account = this.identityConnectorServer.selectedAccount;
        if (!account) {
            return;
        }
        this.identityConnectorServer.selectedAccount = new SharedAccountModel(
            account,
            {
                tokenResult: tokenResult,
            }
        );
    }

    isAuthenticated(): boolean {
        const tokenResult = this.tokenResult;
        return (
            tokenResult != null &&
            moment().isBefore(moment(tokenResult.ExpirationDate))
        );
    }

    hasRole(_: string): boolean {
        return true;
    }

    get account() {
        return this.identityConnectorServer.selectedAccount;
    }

    public authenticateByPassword(login: string, password: string) {
        return this.accountApi
            .LoginByIIN_byCommand(
                new LoginByUserIINCommand({
                    IIN: login,
                    Password: password,
                })
            )
            .pipe(
                mapRight((result) => {
                    this.identityConnectorServer.selectedAccount =
                        new SharedAccountModel({ tokenResult: result });
                    return {
                        HasMultipleAccounts: result.HasMultipleAccounts,
                        TwoFANeeded: !result.TwoFactorAuthPassed,
                    } as AuthServiceSignInResult;
                }),
                mapLeft(mapToApiFailureResponseModel)
            );
    }

    verifyAuthCode(code: string, rememberMe = false) {
        return this.accountApi
            .VerifyAuthCode_byCommand(
                new Verify2FACodeCommand({
                    Code: code,
                    RememberMe: rememberMe,
                })
            )
            .pipe(
                mapRight((result) => {
                    this.identityConnectorServer.selectedAccount =
                        new SharedAccountModel({ tokenResult: result });
                    return {
                        HasMultipleAccounts: result.HasMultipleAccounts,
                        TwoFANeeded: !result.TwoFactorAuthPassed,
                    } as AuthServiceSignInResult;
                }),
                mapLeft(mapToApiFailureResponseModel)
            );
    }

    public getHashForSign() {
        return this.accountApi
            .GetHashForSign()
            .pipe(mapLeft(mapToApiFailureResponseModel));
    }

    public authenticateByCertificate(cacheId: string, signedHash: string) {
        return this.accountApi
            .LoginByCertificate_byCommand(
                new LoginByUserCertificateCommand({
                    CacheId: cacheId,
                    SignedHash: signedHash,
                    IsNotRegistered: false,
                })
            )
            .pipe(
                mapRight((result) => {
                    this.identityConnectorServer.selectedAccount =
                        new SharedAccountModel({ tokenResult: result });
                    return {
                        HasMultipleAccounts: result.HasMultipleAccounts,
                        TwoFANeeded: !result.TwoFactorAuthPassed,
                    } as AuthServiceSignInResult;
                }),
                mapLeft(mapToApiFailureResponseModel)
            );
    }

    public authenticateAsNotRegistered(
        cacheId: string,
        signedHash: string,
        recipientCompanyId: string | null
    ) {
        return this.accountApi
            .LoginByCertificate_byCommand(
                new LoginByUserCertificateCommand({
                    IsNotRegistered: true,
                    CacheId: cacheId,
                    SignedHash: signedHash,
                    RecipientCompanyId: recipientCompanyId,
                }),
                { 'skip-auth': 'true' }
            )
            .pipe
            // mapRight((result) => {
            //     // this.identityConnectorServer.selectedAccount = new SharedAccountModel({ tokenResult: result });
            //     return result;
            // })
            ();
    }

    setAuthOption(option: ApplicationUserTwoFactorAuthOption) {
        return this.accountApi
            .SetTwoFactorAuthOption_byOption(option)
            .pipe(mapLeft(mapToApiFailureResponseModel));
    }

    getTwoFactorAuthOptions() {
        return this.accountApi
            .GetTwoFactorAuthOptions()
            .pipe(mapLeft(mapToApiFailureResponseModel));
    }

    public getUserProfiles() {
        return this.accountApi
            .GetUserAccounts()
            .pipe(mapLeft(mapToApiFailureResponseModel));
    }

    public selectUserAccount(accountId: string) {
        return this.accountApi.SelectUserAccount_byAccountId(accountId).pipe(
            mapRight((result) => {
                this.identityConnectorServer.selectedAccount =
                    new SharedAccountModel({ tokenResult: result });
            }),
            mapLeft(apiResponseErrorHandler),
            mapLeft(showNotification)
        );
    }

    public authModerUser(userId: string) {
        return this.accountApi
            .AuthModerUser_byUserId(userId)
            .pipe(mapLeft(apiResponseErrorHandler), mapLeft(showNotification));
    }

    public resendVerificationCode(
        option: ApplicationUserTwoFactorAuthOption | null = null
    ) {
        return this.accountApi
            .ResendVerificationCode_byOption(option)
            .pipe(mapLeft(mapToApiFailureResponseModel));
    }

    register(registerCommand: RegisterCommand) {
        return this.accountApi.Register_byCommand(registerCommand).pipe(
            mapRight((result) => {
                this.identityConnectorServer.selectedAccount =
                    new SharedAccountModel({ tokenResult: result });
                return {
                    HasMultipleAccounts: result.HasMultipleAccounts,
                    TwoFANeeded: !result.TwoFactorAuthPassed,
                } as AuthServiceSignInResult;
            }),
            mapLeft(mapToApiFailureResponseModel)
        );
    }

    isOrganization() {
        return (
            this.tokenResult?.IssuerType ==
                CertificateIssuerType.ENTERPRENEUR ||
            this.tokenResult?.IssuerType == CertificateIssuerType.ORGANIZATION
        );
    }

    public authenticateBySSO(ticketId: string) {
        return this.accountApi.GetSingleSignOnToken_byTicketId(ticketId).pipe(
            mapRight((result) => {
                this.identityConnectorServer.selectedAccount =
                    new SharedAccountModel({ tokenResult: result });
                return result;
            }),
            mapLeft(mapToApiFailureResponseModel)
        );
    }
}
