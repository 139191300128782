import { SwaggerApiService } from './SwaggerApiService.service';
import { SwaggerApiRequestHeaders } from './SwaggerApiService.service';
import { eitherify, mapLeft, mapRight } from '@dev-stream/utils';
import { Inject, Optional, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BASE_URL, IdentityServerApiRootModule } from './IdentityServerApi.module';
import { TokenResult } from '../models/TokenResult.model';
import { SystemSettingsModel } from '../models/SystemSettingsModel.model';
import { TwoFactorAuthOptionModel } from '../models/TwoFactorAuthOptionModel.model';
import { AccountInfo } from '../models/AccountInfo.model';
import { HashForSignResult } from '../models/HashForSignResult.model';
import { ActiveSessionInfo } from '../models/ActiveSessionInfo.model';
import { ValidateCertificateCommand } from '../models/ValidateCertificateCommand.model';
import { CertificateInfo } from '../models/CertificateInfo.model';
import { ValidateSignedHashCommand } from '../models/ValidateSignedHashCommand.model';
import { ValidateSignedHashCommandResult } from '../models/ValidateSignedHashCommandResult.model';
import { LoginByUserIINCommand } from '../models/LoginByUserIINCommand.model';
import { LoginByUserCertificateCommand } from '../models/LoginByUserCertificateCommand.model';
import { RegisterCommand } from '../models/RegisterCommand.model';
import { ChangePasswordCommand } from '../models/ChangePasswordCommand.model';
import { StringApiSuccessResponseModel } from '../models/StringApiSuccessResponseModel.model';
import { GenerateChangeSettingsTokenCommand } from '../models/GenerateChangeSettingsTokenCommand.model';
import { ChangeSettingsCommand } from '../models/ChangeSettingsCommand.model';
import { ApplicationUserTwoFactorAuthOption } from '../models/ApplicationUserTwoFactorAuthOption.model';
import { Verify2FACodeCommand } from '../models/Verify2FACodeCommand.model';
import { GeneratePasswordResetTokenCommand } from '../models/GeneratePasswordResetTokenCommand.model';
import { ResetPasswordCommand } from '../models/ResetPasswordCommand.model';
import { RegisterEmployeeCommand } from '../models/RegisterEmployeeCommand.model';
import { GuidApiSuccessResponseModel } from '../models/GuidApiSuccessResponseModel.model';

@Injectable({ providedIn: IdentityServerApiRootModule })
export class AccountApiService extends SwaggerApiService {
    public constructor(http: HttpClient, @Optional() @Inject(BASE_URL) baseUrl: string) {
        super(http, baseUrl);
    }

    public GetSingleSignOnToken_byTicketId<T extends TokenResult>(
        ticketId: string,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('get', `${this.baseUrl}/api/v1/account/GetSingleSignOnToken/${ticketId}`, {
            headers: requestHeaders,
            responseType: 'json',
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new TokenResult(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public GetSystemSettings<T extends SystemSettingsModel>(requestHeaders?: SwaggerApiRequestHeaders) {
        const response = this.http.request<T>('get', `${this.baseUrl}/api/v1/account/GetSystemSettings`, {
            headers: requestHeaders,
            responseType: 'json',
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new SystemSettingsModel(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public GetTwoFactorAuthOptions<T extends Array<TwoFactorAuthOptionModel>>(requestHeaders?: SwaggerApiRequestHeaders) {
        const response = this.http.request<T>('get', `${this.baseUrl}/api/v1/account/GetTwoFactorAuthOptions`, {
            headers: requestHeaders,
            responseType: 'json',
        });

        return response.pipe(
            eitherify(),
            mapRight((response) =>
                Array.isArray(response) ? response.map((i) => new TwoFactorAuthOptionModel(i)) : response
            ),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public GetUserAccounts<T extends Array<AccountInfo>>(requestHeaders?: SwaggerApiRequestHeaders) {
        const response = this.http.request<T>('get', `${this.baseUrl}/api/v1/account/GetUserAccounts`, {
            headers: requestHeaders,
            responseType: 'json',
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => (Array.isArray(response) ? response.map((i) => new AccountInfo(i)) : response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public GetHashForSign<T extends HashForSignResult>(requestHeaders?: SwaggerApiRequestHeaders) {
        const response = this.http.request<T>('get', `${this.baseUrl}/api/v1/account/GetHashForSign`, {
            headers: requestHeaders,
            responseType: 'json',
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new HashForSignResult(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public GetActiveSessions<T extends Array<ActiveSessionInfo>>(requestHeaders?: SwaggerApiRequestHeaders) {
        const response = this.http.request<T>('get', `${this.baseUrl}/api/v1/account/GetActiveSessions`, {
            headers: requestHeaders,
            responseType: 'json',
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => (Array.isArray(response) ? response.map((i) => new ActiveSessionInfo(i)) : response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public ValidateCertificate_byCommand<T extends CertificateInfo>(
        command: ValidateCertificateCommand,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/v1/account/ValidateCertificate`, {
            headers: requestHeaders,
            responseType: 'json',
            body: command,
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new CertificateInfo(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public ValidateSignedHash_byCommand<T extends ValidateSignedHashCommandResult>(
        command: ValidateSignedHashCommand,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/v1/account/ValidateSignedHash`, {
            headers: requestHeaders,
            responseType: 'json',
            body: command,
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new ValidateSignedHashCommandResult(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public LoginByIIN_byCommand<T extends TokenResult>(
        command: LoginByUserIINCommand,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/v1/account/LoginByIIN`, {
            headers: requestHeaders,
            responseType: 'json',
            body: command,
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new TokenResult(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public LoginByCertificate_byCommand<T extends TokenResult>(
        command: LoginByUserCertificateCommand,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/v1/account/LoginByCertificate`, {
            headers: requestHeaders,
            responseType: 'json',
            body: command,
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new TokenResult(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public Register_byCommand<T extends TokenResult>(command: RegisterCommand, requestHeaders?: SwaggerApiRequestHeaders) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/v1/account/Register`, {
            headers: requestHeaders,
            responseType: 'json',
            body: command,
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new TokenResult(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public ChangePassword_byCommand<T extends StringApiSuccessResponseModel>(
        command: ChangePasswordCommand,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/v1/account/ChangePassword`, {
            headers: requestHeaders,
            responseType: 'json',
            body: command,
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new StringApiSuccessResponseModel(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public GenerateChangeSettingsToken_byCommand(
        command: GenerateChangeSettingsTokenCommand,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request('post', `${this.baseUrl}/api/v1/account/GenerateChangeSettingsToken`, {
            headers: requestHeaders,
            responseType: 'text',
            body: command,
        });

        return response.pipe(
            eitherify(),

            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public ChangeSettings_byCommand<T extends StringApiSuccessResponseModel>(
        command: ChangeSettingsCommand,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/v1/account/ChangeSettings`, {
            headers: requestHeaders,
            responseType: 'json',
            body: command,
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new StringApiSuccessResponseModel(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public GenerateSwitch2FAToken_byOption(
        option: ApplicationUserTwoFactorAuthOption,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request('post', `${this.baseUrl}/api/v1/account/GenerateSwitch2FAToken/${option}`, {
            headers: requestHeaders,
            responseType: 'text',
        });

        return response.pipe(
            eitherify(),

            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public Switch2FAToken_byCode<T extends StringApiSuccessResponseModel>(
        code: string,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/v1/account/Switch2FAToken/${code}`, {
            headers: requestHeaders,
            responseType: 'json',
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new StringApiSuccessResponseModel(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public VerifyAuthCode_byCommand<T extends TokenResult>(
        command: Verify2FACodeCommand,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/v1/account/VerifyAuthCode`, {
            headers: requestHeaders,
            responseType: 'json',
            body: command,
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new TokenResult(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public SetTwoFactorAuthOption_byOption(
        option: ApplicationUserTwoFactorAuthOption,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request('post', `${this.baseUrl}/api/v1/account/SetTwoFactorAuthOption/${option}`, {
            headers: requestHeaders,
            responseType: 'text',
        });

        return response.pipe(
            eitherify(),

            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public ResendVerificationCode_byOption(
        option: ApplicationUserTwoFactorAuthOption | null,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request('post', `${this.baseUrl}/api/v1/account/ResendVerificationCode/${option}`, {
            headers: requestHeaders,
            responseType: 'text',
        });

        return response.pipe(
            eitherify(),

            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public SelectUserAccount_byAccountId<T extends TokenResult>(
        accountId: string,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/v1/account/SelectUserAccount/${accountId}`, {
            headers: requestHeaders,
            responseType: 'json',
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new TokenResult(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public AuthModerUser_byUserId<T extends StringApiSuccessResponseModel>(
        userId: string,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/v1/account/AuthModerUser/${userId}`, {
            headers: requestHeaders,
            responseType: 'json',
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new StringApiSuccessResponseModel(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public SetDefaultAccount_byAccountId(accountId: string, requestHeaders?: SwaggerApiRequestHeaders) {
        const response = this.http.request('post', `${this.baseUrl}/api/v1/account/SetDefaultAccount/${accountId}`, {
            headers: requestHeaders,
            responseType: 'text',
        });

        return response.pipe(
            eitherify(),

            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public GeneratePasswordResetToken_byCommand<T extends StringApiSuccessResponseModel>(
        command: GeneratePasswordResetTokenCommand,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/v1/account/GeneratePasswordResetToken`, {
            headers: requestHeaders,
            responseType: 'json',
            body: command,
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new StringApiSuccessResponseModel(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public ResetPassword_byCommand<T extends StringApiSuccessResponseModel>(
        command: ResetPasswordCommand,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/v1/account/ResetPassword`, {
            headers: requestHeaders,
            responseType: 'json',
            body: command,
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new StringApiSuccessResponseModel(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public RegisterEmployee_byToken_byCommand<T extends StringApiSuccessResponseModel>(
        token: string,
        command: RegisterEmployeeCommand,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/v1/account/RegisterEmployee/${token}`, {
            headers: requestHeaders,
            responseType: 'json',
            body: command,
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new StringApiSuccessResponseModel(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }

    public RevokeActiveSession_bySessionId<T extends GuidApiSuccessResponseModel>(
        sessionId: string,
        requestHeaders?: SwaggerApiRequestHeaders
    ) {
        const response = this.http.request<T>('post', `${this.baseUrl}/api/v1/account/RevokeActiveSession/${sessionId}`, {
            headers: requestHeaders,
            responseType: 'json',
        });

        return response.pipe(
            eitherify(),
            mapRight((response) => new GuidApiSuccessResponseModel(response)),
            mapLeft((ex) => (ex instanceof HttpErrorResponse ? ex : ex))
        );
    }
}
