import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentRef } from '@angular/core';
import { BaseBladeConfig } from '@idocs/shared-ui/blade';
import { ReplaySubject, Subject } from 'rxjs';
import { BaseModelPart } from '@dev-stream/utils';
import { share } from 'rxjs/operators';

export class BaseBladeRef<TContainerComponent = any, TResult = any> {
    /**
     * Reference on overlay dom element
     */
    overlayRef?: OverlayRef;

    /**
     * Reference to blade wrapper component instance
     */
    containerComponentRef?: ComponentRef<TContainerComponent>;

    /**
     * Blade configuration that was used to open the blade
     */
    configuration?: BaseBladeConfig;

    protected closeSubject = new Subject<TResult>();
    protected openSubject = new ReplaySubject<any>(1);

    /**
     * Observable used to determine when blade is closed
     */
    public onClose = this.closeSubject.asObservable().pipe(share());
    public onOpen = this.openSubject.asObservable().pipe(share());

    constructor(
        ...parts: BaseModelPart<
            BaseBladeRef<TContainerComponent, TResult>,
            'onClose' | 'onOpen'
        >[]
    ) {
        Object.assign(this, ...parts);
    }

    close(result?: TResult, ...params: any[]) {
        this.closeSubject.next(result);
        this.closeSubject.complete();
    }

    opened(...params: any[]) {
        this.openSubject.next(...params);
        this.openSubject.complete();
    }
}
