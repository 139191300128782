import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteBuilder } from 'ng-utils';
import { ErrorPageComponent } from '@idocs/shared-ui/error-pages/components/error-page/error-page.component';

const errorPagesRoutes: Routes = [
    RouteBuilder.withPath('error').withComponent(ErrorPageComponent).build(),
    RouteBuilder.withPath('error/:code').withComponent(ErrorPageComponent).build(),
];

@NgModule({
    imports: [RouterModule.forChild(errorPagesRoutes)],
})
export class ErrorPagesRoutingModule {}
