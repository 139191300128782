import {BaseBladeConfig} from "@idocs/shared-ui/blade/models/baseBladeConfig";
import {BaseModelPart, StrictBaseModelPart} from "@dev-stream/utils";
import {ComponentType} from "@angular/cdk/overlay";
import {TemplateRef} from "@angular/core";
import {BladeAction} from "@idocs/shared-ui/blade/models/bladeAction";
import {Observable} from "rxjs";

/**
 * Configuration of opening blade
 */
export class BladeConfig<TComponent,
    TData = null,
    TResult = void> extends BaseBladeConfig {
    protected BASE_TYPE = BladeConfig;

    constructor(
        ...parts: BaseModelPart<BladeConfig<TComponent, TData, TResult>>[]
    ) {
        super(...parts);
    }



    /**
     * Additional data which will be passed to a component by using {@link BLADE_DATA} injection token or context of a template
     */
    data?: TData;

    /**
     * Component or template to be inserted into the blade
     */
    componentOrTemplate?: ComponentType<TComponent> | TemplateRef<TComponent>;

    /**
     * List of blade actions appended to the bottom of the blade which are used to close it and return result to the {@link BladeRef.onClose} observable
     */
    actions!: BladeAction<TResult, TComponent>[];
    /**
     * Async action which runs right before closing the blade. If the result of the action will be `false` prevents blade of closing. During this action all blade action buttons will be disabled
     */
    beforeClose!: (
        result: TResult | undefined
    ) => boolean | Observable<boolean> | Promise<boolean>;

    GetDefaultValue(): StrictBaseModelPart<BladeConfig<TComponent, TData, TResult>> {
        return {
            ...super.GetDefaultValue(),
            beforeClose: () => Promise.resolve(true),
            actions: [],
        };
    }
}
