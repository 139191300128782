import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog/dialog.component';

import { ButtonModule } from '@idocs/shared-ui/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogsService } from '.';
import { InputsModule } from '@idocs/shared-ui/inputs';
import { AppIconsModule } from '../app-icons';

@NgModule({
    declarations: [DialogComponent],
    imports: [CommonModule, ButtonModule, MatDialogModule, InputsModule, AppIconsModule],
})
export class DialogsModule {
    static forRoot(): ModuleWithProviders<DialogsModuleForRoot> {
        return {
            ngModule: DialogsModuleForRoot,
            providers: [DialogsService],
        };
    }
}

@NgModule({
    declarations: [],
    imports: [DialogsModule],
    exports: [DialogsModule],
})
export class DialogsModuleForRoot {
    
}
