export const AppBreakpoints = {
    XL: '(min-width: 1440px)',
    LT_XL: '(max-width: 1439px)',

    LG: '(min-width: 1224px) and (max-width: 1439px)',
    GT_LG: '(min-width: 1440px)',
    LT_LG: '(max-width: 1223px)',

    MD: '(min-width: 984px) and (max-width: 1223px)',
    GT_MD: '(min-width: 1224px)',
    LT_MD: '(max-width: 983px)',

    SM: '(min-width: 680px) and (max-width: 983px)',
    GT_SM: '(min-width: 984px)',
    LT_SM: '(max-width: 679px)',

    XS: '(max-width: 679px)',
    GT_XS: '(min-width: 680px)',
};
