import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteBuilder } from 'ng-utils';

const routes: Routes = [
    RouteBuilder.withPath('widget')
        .withLoadChildren(() =>
            import(
                './widget-presentation-pages/widget-presentation-pages.module'
            ).then((m) => m.WidgetPresentationPagesModule)
        )
        .build(),
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class HelpdeskPresentationsRoutingModule {}
