import { BaseModel, StrictBaseModelPart, hasUnionKeysStrict } from '@dev-stream/utils';

export class LoginByUserCertificateCommand extends BaseModel<LoginByUserCertificateCommand> {
    protected BASE_TYPE = LoginByUserCertificateCommand;
    'SignedHash'!: string;
    'CacheId'!: string;
    'Tsp'!: string | null;
    'IsNotRegistered'!: boolean | null;
    'RecipientCompanyId'!: string | null;

    public GetDefaultValue(): StrictBaseModelPart<LoginByUserCertificateCommand> {
        return {
            SignedHash: '',
            CacheId: '',
            Tsp: null,
            IsNotRegistered: null,
            RecipientCompanyId: null,
        };
    }

    public static isLoginByUserCertificateCommand(o: any): o is LoginByUserCertificateCommand {
        return o instanceof LoginByUserCertificateCommand || hasUnionKeysStrict(o, ['SignedHash', 'CacheId']);
    }
}
