<div class="content-wrapper py-12">
    <div
        class="content"
        [class.line-clamp-1]="contentClamped"
        [class.lt-md:line-clamp-2]="contentClamped"
        (click)="contentClamped = !contentClamped"
    >
        <ng-content></ng-content>
    </div>
</div>

<div class="actions flex">
    <ng-content select="[banner-action]"></ng-content>
</div>
