<div class="blade-container bg-white flex flex-col items-stretch shadow-level-1">
    <div class="blade-container-top flex justify-end">
        <h4 class="text-header-4 font-header-4 text-primary-main mt-32 mb-8 px-40 flex-1" *ngIf="bladeTitle">
            {{ bladeTitle }}
        </h4>
        <shared-ui-button class="mr-12 mt-12" appearance="icon" color="neutral" [disabled]="isClosing" (click)="close()">
            <ic-icon [icon]="closeIcon"></ic-icon>
        </shared-ui-button>
    </div>
    <div
        class="
            blade-container-content
            scroll scroll-thumb-neutral-light scroll-track-transparent
            flex flex-col
            items-stretch
            flex-1
            px-40
            pb-12
        "
        [class.mb-20]="!actions.length"
    >
        <ng-container cdkPortalOutlet></ng-container>
    </div>
    <div class="actions px-40 mb-32 flex self-stretch justify-end gap-12 mt-12" *ngIf="actions.length">
        <shared-ui-button
            *ngFor="let action of actions"
            [appearance]="action.appearance"
            [color]="action.color"
            [tooltip]="actionTooltip(action)"
            [disabled]="isActionDisabled(action)"
            [loading]="isActionLoading(action)"
            (click)="closeByAction(action)"
            >{{ action.text }}</shared-ui-button
        >
    </div>
</div>
