import {
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    Injector,
} from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import closeIcon from '@iconify/icons-mdi/close';
import {
    CdkPortalOutlet,
    ComponentPortal,
    ComponentType,
    TemplatePortal,
} from '@angular/cdk/portal';
import { IconifyIcon } from '@idocs/icons';
import { BladeAction } from '../models/bladeAction';
import { BladeRef } from '../models/bladeRef';

@Component({
    selector: 'shared-ui-blade-container',
    templateUrl: './blade-container.component.html',
    styleUrls: ['./blade-container.component.scss'],
})
export class BladeContainerComponent<TComponent, TData = null, TResult = void>
    extends BaseComponent
    implements OnInit
{
    closeIcon: IconifyIcon = closeIcon;

    @ViewChild(CdkPortalOutlet, { static: true })
    cdkPortalOutlet?: CdkPortalOutlet;

    portal?: ComponentPortal<TComponent> | TemplatePortal<TComponent>;

    componentOrTemplate?: ComponentType<TComponent> | TemplateRef<TComponent>;

    actions: BladeAction<TResult, TComponent>[] = [];
    bladeTitle?: string | null;

    constructor(
        private bladeRef: BladeRef<TComponent, TData, TResult>,
        private vcr: ViewContainerRef,
        injector: Injector
    ) {
        super(injector);
        this.actions = bladeRef.configuration?.actions ?? [];
        this.bladeTitle = bladeRef.configuration?.title;
    }

    get isClosing() {
        return this.bladeRef.isClosing;
    }

    ngOnInit(): void {}

    close() {
        this.bladeRef.close();
    }

    attachContent(
        componentOrTemplate: ComponentType<TComponent> | TemplateRef<TComponent>
    ) {
        if (componentOrTemplate instanceof TemplateRef) {
            this.portal = new TemplatePortal(
                componentOrTemplate,
                this.vcr,
                {} as TComponent
            );
            return this.cdkPortalOutlet?.attach(this.portal);
        } else {
            this.portal = new ComponentPortal(
                componentOrTemplate,
                this.vcr,
                this.componentInjector
            );
            this.bladeRef.componentRef = this.cdkPortalOutlet?.attach(
                this.portal
            );
            return this.bladeRef.componentRef;
        }
    }

    closeByAction(action: BladeAction<TResult, TComponent>) {
        if (
            BladeAction.isResultCalculated<TComponent, TResult>(action.result)
        ) {
            const bladeResult = action.result(
                this.bladeRef.componentRef!.instance
            );
            this.bladeRef.close(bladeResult, action);
        } else {
            this.bladeRef.close(action.result, action);
        }
    }

    isActionDisabled(action: BladeAction<TResult, TComponent>) {
        return (
            (this.isClosing && this.bladeRef.closedByAction != action) ||
            (this.bladeRef.componentRef?.instance &&
                action.disabled(this.bladeRef.componentRef.instance))
        );
    }

    isActionLoading(action: BladeAction<TResult, TComponent>) {
        return this.bladeRef.closedByAction == action;
    }

    actionTooltip(action: BladeAction<TResult, TComponent>) {
        if (!this.bladeRef.componentRef?.instance || !action.tooltip) {
            return undefined;
        }
        return action.tooltip(this.bladeRef.componentRef.instance);
    }
}
