import { Component, Inject, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { IconifyIcon, idocsLogo } from '@idocs/icons';

import arrow from '@iconify/icons-mdi/arrow-back';
import help from '@iconify/icons-mdi/help-circle';
import { HOME_PAGE_CONFIG, IHomePageConfig } from './../../tokens';

@Component({
    selector: 'shared-ui-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent extends BaseComponent implements OnInit {
    constructor(
        injector: Injector,
        @Inject(HOME_PAGE_CONFIG) public homePageConfig: IHomePageConfig,
        private activatedRoute: ActivatedRoute
    ) {
        super(injector);
    }

    idocsLogo = idocsLogo;
    homeIcon: IconifyIcon = arrow;
    helpIcon: IconifyIcon = help;

    code: string = '000';
    header: string = 'Что то пошло не так';
    content: string =
        'Возможно, Вы перешли по нерабочей ссылке или попытались открыть несуществующий документ или' +
        ' страницу, или у Вас нет прав доступа к данному документу или странице.';

    ngOnInit(): void {
        this.activatedRoute.paramMap
            .pipe(takeUntil(this.destroyed))
            .subscribe(this.extractConfig.bind(this));
    }

    private extractConfig(paramMap: ParamMap) {
        if (paramMap.has('code')) {
            this.code = paramMap.get('code')!;
        }
        if (paramMap.has('header')) {
            this.header = paramMap.get('header')!;
        }
        if (paramMap.has('content')) {
            this.content = paramMap.get('content')!;
        }
    }
}
