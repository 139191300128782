import { Directive, ElementRef, Injector, Optional, Self } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { MatFormFieldControl } from '@angular/material/form-field';

@Directive({
    selector:
        'input[sharedUiInput], textarea[sharedUiInput], mat-select[sharedUiInput]',
})
export class InputDirective extends BaseComponent {
    constructor(
        injector: Injector,
        @Self()
        @Optional()
        public matFormFieldControl: MatFormFieldControl<any> | null,
        private elementRef: ElementRef<HTMLInputElement | HTMLTextAreaElement>
    ) {
        super(injector);
        this.displayContents = false;
    }

    public focus() {
        this.elementRef.nativeElement.focus();
    }
}
