import { ComponentRef } from '@angular/core';
import { BladeContainerComponent } from '../blade-container/blade-container.component';
import { BladeConfig } from './bladeConfig';
import { BladeAction } from './bladeAction';
import { from, of, ReplaySubject } from 'rxjs';
import { share, take } from 'rxjs/operators';
import { BaseBladeRef } from './baseBladeRef';
import { BaseModelPart } from '@dev-stream/utils';

/**
 * Class helper for getting opened blade reference and configuration
 */
export class BladeRef<
    TComponent,
    TData = null,
    TResult = void
> extends BaseBladeRef<
    BladeContainerComponent<TComponent, TData, TResult>,
    TResult
> {
    /**
     * Reference on blade component instance
     */
    componentRef?: ComponentRef<TComponent>;

    /**
     * Blade configuration that was used to open the blade
     */
    configuration?: BladeConfig<TComponent, TData, TResult>;

    /**
     * Flag that shows if the {@link BladeConfig.beforeClose} action is being executed
     */
    isClosing = false;

    /**
     * Action which was used to close the blade
     */
    closedByAction?: BladeAction<TResult, TComponent>;

    constructor(
        ...parts: BaseModelPart<BladeRef<TComponent, TData, TResult>>[]
    ) {
        super(...parts);
    }

    /**
     * Method to programmatically close the blade
     * @param result The result of the blade which will be passed to {@link BladeRef.onClose} observable
     * @param closedByAction provide action to make it loading state while closing the blade
     */
    close(result?: TResult, closedByAction?: BladeAction<TResult, TComponent>) {
        if (!this.configuration) {
            throw new Error('No configuration provided');
        }
        this.isClosing = true;
        const beforeCloseResult = this.configuration.beforeClose(result);
        const closeObservable = from(
            typeof beforeCloseResult == 'boolean'
                ? of(beforeCloseResult)
                : beforeCloseResult
        ).pipe(take(1), share());
        closeObservable.subscribe((canClose) => {
            if (canClose) {
                this.closeSubject.next(result);
                this.closeSubject.complete();
            }
            this.isClosing = false;
            delete this.closedByAction;
        });
        return closeObservable;
    }

    opened(instance: TComponent) {
        this.openSubject.next(instance);
        this.openSubject.complete();
    }
}
