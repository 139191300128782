import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AlertDialogConfiguration, DialogButtonConfiguration, DialogConfiguration, PromptDialogConfiguration } from '.';
import { DialogComponent } from './dialog/dialog.component';
import { ConfirmDialogConfiguration } from './models';

@Injectable()
export class DialogsService {
    constructor(private matDialog: MatDialog) {}
    readonly defaultMatDialogConfig: MatDialogConfig = { hasBackdrop: true, maxWidth: 500, disableClose: false };

    openConfirmDialog(
        configuration: ConfirmDialogConfiguration | null,
        matDialogConfig: MatDialogConfig = this.defaultMatDialogConfig
    ) {
        const config = new DialogConfiguration({
            title: configuration?.title,
            content: configuration?.content,
            templatesContextData: configuration?.templatesContextData,
            hasInput: false,
            hasCloseIcon: configuration?.hasCloseIcon,
            buttons: [
                new DialogButtonConfiguration<boolean>({
                    result: false,
                    text: configuration?.cancelBtnText,
                    color: configuration?.cancelBtnColor,
                    appearance: configuration?.cancelBtnAppearance,
                }),
                new DialogButtonConfiguration<boolean>({
                    result: true,
                    text: configuration?.acceptBtnText,
                    color: configuration?.acceptBtnColor,
                    appearance: configuration?.acceptBtnAppearance,
                }),
            ],
        });
        const ref = this.matDialog.open(DialogComponent, { ...matDialogConfig, data: config });
        return ref;
    }

    openAlertDialog(
        configuration: AlertDialogConfiguration | null,
        matDialogConfig: MatDialogConfig = this.defaultMatDialogConfig
    ) {
        const config = new DialogConfiguration({
            title: configuration?.title,
            content: configuration?.content,
            templatesContextData: configuration?.templatesContextData,
            hasInput: false,
            hasCloseIcon: configuration?.hasCloseIcon,
            buttons: [
                new DialogButtonConfiguration<boolean>({
                    result: true,
                    text: configuration?.btnText,
                    color: configuration?.btnColor,
                    appearance: configuration?.btnAppearance,
                }),
            ],
        });
        const ref = this.matDialog.open(DialogComponent, { ...matDialogConfig, data: config });
        return ref;
    }

    openPromptDialog(
        configuration: PromptDialogConfiguration | null,
        matDialogConfig: MatDialogConfig = this.defaultMatDialogConfig
    ) {
        const config = new DialogConfiguration({
            title: configuration?.title,
            content: configuration?.content,
            templatesContextData: configuration?.templatesContextData,
            hasInput: true,
            hasCloseIcon: configuration?.hasCloseIcon,
            inputConfiguration: configuration?.inputConfiguration,
            buttons: [
                new DialogButtonConfiguration<any>({
                    result: null,
                    text: configuration?.cancelBtnText,
                    color: configuration?.cancelBtnColor,
                    appearance: configuration?.cancelBtnAppearance,
                }),
                new DialogButtonConfiguration<any>({
                    isInputResult: true,
                    text: configuration?.acceptBtnText,
                    color: configuration?.acceptBtnColor,
                    appearance: configuration?.acceptBtnAppearance,
                }),
            ],
        });
        const ref = this.matDialog.open(DialogComponent, { ...matDialogConfig, data: config });
        return ref;
    }

    openCustomDialog(
        configuration: DialogConfiguration | null,
        matDialogConfig: MatDialogConfig = this.defaultMatDialogConfig
    ) {
        const ref = this.matDialog.open(DialogComponent, { ...matDialogConfig, data: configuration });
        return ref;
    }
}
