import { Directive, HostBinding, Injector } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';

@Directive({
    selector: '[sharedUiInputLabel]',
})
export class InputLabelDirective extends BaseComponent {
    constructor(injector: Injector) {
        super(injector);
        this.displayContents = false;
    }

    @HostBinding('class')
    get getLabelClass() {
        return this.classes == ''
            ? 'uppercase font-label-2 text-label-2 font-medium text-primary-main mb-2'
            : this.classes;
    }
}
