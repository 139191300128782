<div
    class="text-input-container w-full flex flex-col items-stretch {{
        classes
    }}"
>
    <div class="flex justify-between items-center">
        <ng-content select="[sharedUiInputLabel]"></ng-content>
        <ng-content select="[sharedUiInputHint]"></ng-content>
    </div>
    <mat-form-field subscriptSizing="dynamic" appearance="outline">
        <ng-content select="[sharedUiInput]"></ng-content>

        <div matSuffix class="input-suffix flex items-center flex-none gap-12">
            <ng-content select="[inputSuffix]"></ng-content>
        </div>
        <div matPrefix class="input-prefix flex items-center flex-none gap-12">
            <ng-content select="[inputPrefix]"></ng-content>
        </div>
        <mat-error>
            <ng-content select="[sharedUiInputError]"></ng-content>
        </mat-error>
    </mat-form-field>
</div>
