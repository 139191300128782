import { Inject, ModuleWithProviders, NgModule, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelpdeskPresentationsRoutingModule } from './helpdesk-presentations-routing.module';
import { HelpdeskPresentationsComponentsModule } from '@idocs/shared/helpdesk-presentations/helpdesk-presentations-components/helpdesk-presentations-components.module';
import { IdocsServerEnvironment } from '@idocs/shared-logic/server-environment';
import { HELPDESK_PRESENTATIONS_SERVER_ENVIRONMENT_TOKEN } from '@idocs/shared/helpdesk-presentations/tokens/server-environment.token';
import { IHelpdeskPresentationsConfiguration } from '@idocs/shared/helpdesk-presentations/helpdesk-presentations.configuration';
import { HELPDESK_PRESENTATIONS_CONFIG } from '@idocs/shared/helpdesk-presentations/tokens/helpdesk-presentations-config.token';
import { SIGN_WIDGET_IFRAME_URL } from '@idocs/shared/helpdesk-presentations/tokens/sign-widget.tokens';
import { DialogsModule } from '@idocs/shared-ui/dialogs';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HelpdeskPresentationsRoutingModule,
        HelpdeskPresentationsComponentsModule,
        DialogsModule.forRoot(),
    ],
    exports: [],
})
export class HelpdeskPresentationsModule {
    constructor(
        @Optional()
        @Inject(HELPDESK_PRESENTATIONS_SERVER_ENVIRONMENT_TOKEN)
        serverEnvironment: IdocsServerEnvironment | null
    ) {
        if (serverEnvironment == null) {
            throw new Error(
                `Please include HelpdeskPresentationsModule.forRoot module in your application root module`
            );
        }
    }

    static forRoot(
        config: IHelpdeskPresentationsConfiguration
    ): ModuleWithProviders<HelpdeskPresentationsRootModule> {
        return {
            ngModule: HelpdeskPresentationsRootModule,
            providers: [
                {
                    provide: HELPDESK_PRESENTATIONS_SERVER_ENVIRONMENT_TOKEN,
                    useValue: config.serverEnvironment,
                },
                {
                    provide: SIGN_WIDGET_IFRAME_URL,
                    useValue:
                        config.signWidgetIframeUrl ?? 'https://widget.idocs.kz',
                },
                {
                    provide: HELPDESK_PRESENTATIONS_CONFIG,
                    useValue: config,
                },
            ],
        };
    }
}

@NgModule({
    declarations: [],
    imports: [HelpdeskPresentationsModule],
    exports: [HelpdeskPresentationsModule],
})
export class HelpdeskPresentationsRootModule {}
