import { createPolicy } from 'ng-authorize';
import { AuthService } from './services/auth.service';
import { environment } from '../environments/environment';
import { IdocsServerEnvironment } from '@idocs/shared-logic/server-environment';

export namespace IdentityPolicies {
    export const Authenticated = createPolicy(
        'identity.policies.Authenticated',
        async (authService: AuthService) => authService.isAuthenticated(),
        [AuthService]
    );
    export const IsAdmin = createPolicy(
        'adminPolicy',
        async (authService: AuthService) =>
            authService.isAuthenticated() && authService.hasRole('admin'),
        [AuthService]
    );

    export const IsIndividual = createPolicy(
        'identity.policies.IsIndividual',
        async (authService: AuthService) => !authService.isOrganization(),
        [AuthService]
    );

    export const AuthenticatedOnDevEnv = createPolicy(
        'identity.policies.AuthenticatedOnDevEnv',
        async (authService: AuthService) => {
            console.log(
                'checking env',
                environment.isProductionMode,
                environment.env,
                authService.isAuthenticated()
            );
            return (
                environment.env != IdocsServerEnvironment.Production &&
                authService.isAuthenticated()
            );
        },

        [AuthService]
    );
}

export const IDENTITY_POLICIES = [
    IdentityPolicies.Authenticated,
    IdentityPolicies.AuthenticatedOnDevEnv,
];
