import {
    ComponentType,
    Overlay,
    OverlayRef,
    PositionStrategy,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    ComponentRef,
    Injectable,
    Injector,
    TemplateRef,
    Type,
} from '@angular/core';
import { BladeContainerComponent } from './blade-container/blade-container.component';
import { BLADE_CONFIGURATION, BLADE_DATA } from './tokens';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntil } from 'rxjs/operators';
import { AppBreakpoints } from '@idocs/shared-ui/models';
import {
    BLADE_OVERLAY_LEFT_POSITION,
    BLADE_OVERLAY_RIGHT_POSITION,
} from './constants';
import { BladeConfig } from './models/bladeConfig';
import { BladeConfiguration } from './models/bladeConfiguration';
import { BladeRef } from './models/bladeRef';
import { BaseBladeConfig } from './models/baseBladeConfig';
import { Observable } from 'rxjs';
import { BaseBladeRef } from '@idocs/shared-ui/blade/models/baseBladeRef';

/**
 * Service used to open blades
 * TODO Provide more detailed documentation
 */
@Injectable()
export class BladeService {
    private blades: BaseBladeRef[] = [];

    constructor(
        private overlay: Overlay,
        private breakpointObserver: BreakpointObserver
    ) {}

    #open<TTemplate, TData = null, TResult = void>(
        config: BladeConfig<TTemplate, TData, TResult>
    ): BladeRef<TTemplate, TData, TResult> {
        if (!config.componentOrTemplate) {
            throw new Error('Component or template must be provided');
        }

        const overlayRef = this.createOverlay(config);

        const bladeRef = new BladeRef<TTemplate, TData, TResult>({
            overlayRef,
            configuration: config,
        });

        const injector = this.createInjector(config, overlayRef, bladeRef);

        const portal = this.createContainerPortal<
            BladeContainerComponent<TTemplate, TData, TResult>
        >(injector, BladeContainerComponent);

        this.openCustomBlade(overlayRef, config, bladeRef, portal);

        const instanceRef =
            bladeRef.containerComponentRef?.instance.attachContent(
                config.componentOrTemplate
            );

        if (instanceRef instanceof ComponentRef) {
            bladeRef.opened(instanceRef.instance);
        } else if (instanceRef) {
            bladeRef.opened(instanceRef.context);
        }

        return bladeRef;
    }

    public openCustomBlade<TContainerComponent>(
        overlayRef: OverlayRef,
        configuration: BaseBladeConfig,
        bladeRef: BaseBladeRef,
        portal: ComponentPortal<TContainerComponent>,
    ) {
        bladeRef.containerComponentRef = overlayRef?.attach(portal);

        bladeRef.onClose.subscribe(() => {
            configuration?.id != null && this.disposeBlade(configuration?.id);
        });

        if (configuration.closeOnBackdropClick) {
            overlayRef?.backdropClick().subscribe(() => bladeRef.close());
        }
        this.watchBreakpoints(bladeRef.onClose, overlayRef);

        this.blades.push(bladeRef);
    }

    /**
     * @deprecated
     * @param componentOrTemplate
     * @param config
     */
    open<TTemplate, TData = null, TResult = void>(
        componentOrTemplate: ComponentType<TTemplate> | TemplateRef<TTemplate>,
        config: BladeConfiguration<TData, TResult>
    ): BladeRef<TTemplate, TData, TResult>;

    open<TTemplate, TData = null, TResult = void>(
        config: BladeConfig<TTemplate, TData, TResult>
    ): BladeRef<TTemplate, TData, TResult>;

    open<TTemplate, TData = null, TResult = void>(
        componentOrTemplateOrConfig:
            | BladeConfig<TTemplate, TData, TResult>
            | ComponentType<TTemplate>
            | TemplateRef<TTemplate>,
        config?: BladeConfiguration<TData, TResult>
    ) {
        if (componentOrTemplateOrConfig instanceof BladeConfig) {
            return this.#open(componentOrTemplateOrConfig);
        } else {
            return this.#open(
                new BladeConfig<TTemplate, TData, TResult>(config ?? {}, {
                    componentOrTemplate: componentOrTemplateOrConfig,
                })
            );
        }
    }

    private disposeBlade(id: string) {
        const children = this.blades.filter(
            (b) => b.configuration?.parent == id
        );
        if (children.length) {
            children.forEach(
                (child) =>
                    child.configuration?.id != null &&
                    this.disposeBlade(child.configuration?.id)
            );
        }
        const blade = this.blades.splice(
            this.blades.findIndex((b) => b.configuration?.id == id),
            1
        );
        blade[0]?.overlayRef?.dispose();
    }

    public createContainerPortal<TContainerComponent>(
        injector: Injector,
        container: Type<TContainerComponent>
    ) {
        return new ComponentPortal<TContainerComponent>(
            container,
            null,
            injector
        );
    }

    private createInjector<TTemplate, TData, TResult>(
        config: BladeConfig<TTemplate, TData, TResult>,
        overlayRef: OverlayRef,
        bladeRef: BladeRef<TTemplate, TData, TResult>
    ) {
        return Injector.create({
            providers: [
                { provide: OverlayRef, useValue: overlayRef },
                { provide: BLADE_DATA, useValue: config.data },
                {
                    provide: BLADE_CONFIGURATION,
                    useValue: config,
                },
                { provide: BladeRef, useValue: bladeRef },
            ],
        });
    }

    public createOverlay(config: BaseBladeConfig) {
        let position: PositionStrategy | undefined;
        const parent = this.blades.find(
            (b) => b.configuration?.id == config.parent
        );
        if (!parent) {
            if (config.side == 'left') {
                position = this.overlay
                    .position()
                    .global()
                    .left('0px')
                    .top('0px');
            } else {
                position = this.overlay
                    .position()
                    .global()
                    .right('0px')
                    .top('0px');
            }
        } else {
            const positionBuilder = this.overlay.position();
            if (parent.overlayRef) {
                const connectedPosition =
                    parent.configuration?.side == 'left'
                        ? BLADE_OVERLAY_LEFT_POSITION
                        : BLADE_OVERLAY_RIGHT_POSITION;
                position = positionBuilder
                    .flexibleConnectedTo(parent.overlayRef.overlayElement)
                    .withPositions([connectedPosition]);
            }
        }

        return this.overlay.create({
            backdropClass: config.hasBackdrop
                ? ['bg-primary-main', 'bg-opacity-60']
                : '',
            hasBackdrop: config.hasBackdrop,
            disposeOnNavigation: config.disposeOnNavigation,
            height: '100%',
            panelClass: [
                ...(config.panelClasses || []),
                'flex',
                'flex-col',
                'items-stretch',
                'default-blade-width',
            ],
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy: position,
            ...(config.overlayConfig ?? {}),
        });
    }

    private watchBreakpoints(
        watchUntil: Observable<any>,
        overlayRef: OverlayRef
    ) {
        this.breakpointObserver
            .observe(AppBreakpoints.LT_SM)
            .pipe(takeUntil(watchUntil))
            .subscribe((state) => {
                if (state.matches) {
                    overlayRef.addPanelClass('fullscreen-blade');
                    // overlayRef.updateSize({ width: '100%', height: '100%' });
                } else {
                    overlayRef.removePanelClass('fullscreen-blade');
                    // overlayRef.updateSize({
                    //     width: config.overlayConfig?.width ?? 'auto',
                    //     height: config.overlayConfig?.height ?? '100%',
                    // });
                }
            });
    }
}
