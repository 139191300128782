import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';
import { AppColor } from '@idocs/shared-ui/models';

export class BannerAction extends BaseModel<BannerAction> {
    GetDefaultValue(): StrictBaseModelPart<BannerAction, never> {
        return {};
    }
    protected BASE_TYPE = BannerAction;

    id?: string;
    text?: string;
    action?: () => void;
}

export class BannerConfig extends BaseModel<BannerConfig> {
    GetDefaultValue(): StrictBaseModelPart<BannerConfig, never> {
        return {
            actions: [],
            color: 'accent',
        };
    }
    protected BASE_TYPE = BannerConfig;

    id?: string;
    color!: AppColor;
    text?: string;
    actions!: BannerAction[];
}
