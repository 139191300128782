import { BaseModel, StrictBaseModelPart, hasUnionKeysStrict } from '@dev-stream/utils';

export class LoginByUserIINCommand extends BaseModel<LoginByUserIINCommand> {
    protected BASE_TYPE = LoginByUserIINCommand;
    'IIN'!: string;
    'Password'!: string;

    public GetDefaultValue(): StrictBaseModelPart<LoginByUserIINCommand> {
        return {
            IIN: '',
            Password: '',
        };
    }

    public static isLoginByUserIINCommand(o: any): o is LoginByUserIINCommand {
        return o instanceof LoginByUserIINCommand || hasUnionKeysStrict(o, ['IIN', 'Password']);
    }
}
