import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawerLayoutComponent } from './drawer-layout/drawer-layout.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AppIconsModule } from '@idocs/shared-ui/app-icons';
import { ButtonModule } from '@idocs/shared-ui/button';
import { SidenavLayoutComponent } from './sidenav-layout/sidenav-layout.component';
import { ContentLayoutComponent } from './content-layout/content-layout.component';
import { DrawerLinkComponent } from './drawer-link/drawer-link.component';
import { RouterModule } from '@angular/router';
import { DrawerLinksListComponent } from './drawer-links-list/drawer-links-list.component';

@NgModule({
    declarations: [
        DrawerLayoutComponent,
        ToolbarComponent,
        SidenavLayoutComponent,
        ContentLayoutComponent,
        DrawerLinkComponent,
        DrawerLinksListComponent,
    ],
    imports: [
        CommonModule,
        MatSidenavModule,
        AppIconsModule,
        ButtonModule,
        RouterModule,
    ],
    exports: [
        DrawerLayoutComponent,
        ToolbarComponent,
        SidenavLayoutComponent,
        ContentLayoutComponent,
        DrawerLinkComponent,
        DrawerLinksListComponent,
    ],
})
export class DrawerLayoutModule {}
