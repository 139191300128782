import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Injector,
} from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BannerAction, BannerConfig } from '../../models';
import { BannerService } from '../../services';

@Component({
    selector: 'shared-ui-banner-container',
    templateUrl: './banner-container.component.html',
    styleUrls: ['./banner-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerContainerComponent extends BaseComponent implements OnInit {
    public banners: BannerConfig[] = [];

    constructor(
        injector: Injector,
        private bannerService: BannerService,
        private cdr: ChangeDetectorRef
    ) {
        super(injector);
    }

    ngOnInit(): void {
        merge(
            this.bannerService.closed().pipe(takeUntil(this.destroyed)),
            this.bannerService.opened().pipe(takeUntil(this.destroyed))
        )
            .pipe(takeUntil(this.destroyed))
            .subscribe(() => {
                this.banners = this.bannerService.banners.map((b) => b.config);
                this.cdr.markForCheck();
            });
    }

    handleAction(banner: BannerConfig, action: BannerAction) {
        this.bannerService.handleAction(banner, action);
    }
}
