import { ApplicationUserTwoFactorAuthOption } from './ApplicationUserTwoFactorAuthOption.model';
import { BaseModel, StrictBaseModelPart, EnumProperty, hasUnionKeys } from '@dev-stream/utils';

export class TwoFactorAuthOptionModel extends BaseModel<TwoFactorAuthOptionModel> {
    protected BASE_TYPE = TwoFactorAuthOptionModel;
    @EnumProperty(() => ApplicationUserTwoFactorAuthOption)
    'Option'!: ApplicationUserTwoFactorAuthOption | null;
    'Message'!: string | null;
    'IsDefault'!: boolean | null;

    public GetDefaultValue(): StrictBaseModelPart<TwoFactorAuthOptionModel> {
        return {
            Option: null,
            Message: null,
            IsDefault: null,
        };
    }

    public static isTwoFactorAuthOptionModel(o: any): o is TwoFactorAuthOptionModel {
        return o instanceof TwoFactorAuthOptionModel || hasUnionKeys(o, ['Option', 'Message', 'IsDefault']);
    }
}
