import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerContainerComponent } from './components/banner-container/banner-container.component';
import { BannerComponent } from './components/banner/banner.component';
import { ButtonModule } from '@idocs/shared-ui/button';

@NgModule({
    declarations: [BannerContainerComponent, BannerComponent],
    imports: [CommonModule, ButtonModule],
    exports: [BannerContainerComponent, BannerComponent],
})
export class BannerModule {}
