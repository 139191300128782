import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BannerAction, BannerConfig } from './../models';

@Injectable({ providedIn: 'root' })
export class BannerService {
    public readonly banners: { config: BannerConfig; closeActionId?: string }[] = [];

    private bannerOpenedSubject = new Subject<BannerConfig>();
    public opened() {
        return this.bannerOpenedSubject.asObservable();
    }

    private bannerClosedSubject = new Subject<BannerConfig>();
    public closed() {
        return this.bannerClosedSubject.asObservable();
    }

    public open(config: BannerConfig, closeActionId?: string) {
        if (this.banners.some((c) => c.config.id == config.id)) {
            return;
        }
        this.banners.push({ config, closeActionId });
        this.bannerOpenedSubject.next(config);
    }

    public close(id: string) {
        var bannerIndex = this.banners.findIndex((b) => b.config.id == id);
        if (bannerIndex >= 0) {
            const closedBanner = this.banners.splice(bannerIndex, 1);
            this.bannerClosedSubject.next(closedBanner[0].config);
        }
    }

    handleAction(_banner: BannerConfig, _action: BannerAction) {
        const banner = this.banners.find((b) => b.config.id == _banner.id);
        if (!banner) {
            return;
        }
        var action = banner.config.actions?.find((a) => a.id == _action.id);
        if (!action) {
            return;
        }

        if (action.action) {
            action.action();
        }
        if (banner.closeActionId == action.id && banner.config.id) {
            this.close(banner.config.id);
        }
    }
}
