import { registerLocaleData } from '@angular/common';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { IdentityModule } from './app/identity.module';
import { environment } from './environments/environment';

import localeRu from '@angular/common/locales/ru';

registerLocaleData(localeRu);

if (environment.isProductionMode) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(IdentityModule)
    .catch((err) => console.error(err));
