import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ITokenObtainer } from '@idocs/shared-logic';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class TokenObtainer implements ITokenObtainer {
    constructor(protected authService: AuthService) {}

    obtainToken(_: HttpRequest<any>): string {
        return `Bearer ${this.authService.account?.tokenResult?.Token}`;
    }

    skipTokenInsertion(req: HttpRequest<any>): boolean {
        return req.headers.get('skip-auth') == 'true' || !this.authService.account;
    }
}
