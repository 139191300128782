import { BaseModel, StrictBaseModelPart, hasUnionKeysStrict } from '@dev-stream/utils';

export class Verify2FACodeCommand extends BaseModel<Verify2FACodeCommand> {
    protected BASE_TYPE = Verify2FACodeCommand;
    'Code'!: string;
    'RememberMe'!: boolean | null;

    public GetDefaultValue(): StrictBaseModelPart<Verify2FACodeCommand> {
        return {
            Code: '',
            RememberMe: null,
        };
    }

    public static isVerify2FACodeCommand(o: any): o is Verify2FACodeCommand {
        return o instanceof Verify2FACodeCommand || hasUnionKeysStrict(o, ['Code']);
    }
}
