<div class="banners-container flex flex-col flex-none items-stretch justify-start shadow-level-1 z-40">
    <shared-ui-banner *ngFor="let banner of banners" [color]="banner.color">
        <p *ngIf="banner.text">{{ banner.text }}</p>
        <shared-ui-button
            banner-action
            [color]="banner.color"
            (click)="handleAction(banner, action)"
            *ngFor="let action of banner.actions"
            >{{ action.text }}</shared-ui-button
        >
    </shared-ui-banner>
</div>
<ng-content></ng-content>
