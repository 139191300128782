import { BaseModel, StrictBaseModelPart, Property, hasUnionKeys } from '@dev-stream/utils';

export class ActiveSessionInfo extends BaseModel<ActiveSessionInfo> {
    protected BASE_TYPE = ActiveSessionInfo;
    'Id'!: string | null;
    @Property(() => Date)
    'TokenIssueDate'!: Date | null;
    @Property(() => Date)
    'TokenExpirationDate'!: Date | null;
    'IpAddress'!: string | null;
    'UserAgent'!: string | null;
    'IsCurrent'!: boolean | null;

    public GetDefaultValue(): StrictBaseModelPart<ActiveSessionInfo> {
        return {
            Id: null,
            TokenIssueDate: null,
            TokenExpirationDate: null,
            IpAddress: null,
            UserAgent: null,
            IsCurrent: null,
        };
    }

    public static isActiveSessionInfo(o: any): o is ActiveSessionInfo {
        return (
            o instanceof ActiveSessionInfo ||
            hasUnionKeys(o, ['Id', 'TokenIssueDate', 'TokenExpirationDate', 'IpAddress', 'UserAgent', 'IsCurrent'])
        );
    }
}
