<div class="relative pt-24">
    <div
        *ngIf="config.hasCloseIcon"
        class="absolute cursor-pointer text-neutral-light text-header-4 top-8 right-8"
    >
        <ic-icon [icon]="close" mat-dialog-close></ic-icon>
    </div>
    <ng-container *ngIf="config.title && titleTemplate">
        <ng-container
            *ngTemplateOutlet="
                titleTemplate;
                context: { $implicit: config.templatesContextData }
            "
        >
        </ng-container>
    </ng-container>
    <mat-dialog-content *ngIf="config.content && contentTemplate">
        <ng-container
            *ngTemplateOutlet="
                contentTemplate;
                context: { $implicit: config.templatesContextData }
            "
        ></ng-container>
    </mat-dialog-content>
    <ng-container *ngIf="config.hasInput">
        <div class="mt-10">
            <shared-ui-input>
                <span *ngIf="config.inputConfiguration" sharedUiInputLabel>{{
                    config.inputConfiguration.label
                }}</span>
                <input
                    #promptInput
                    [type]="config.inputConfiguration.type"
                    matInput
                    sharedUiInput
                    [formControl]="field"
                />
            </shared-ui-input>
        </div>
    </ng-container>

    <mat-dialog-actions
        align="end"
        class="flex gap-12"
        *ngIf="config.buttons.length > 0"
    >
        <ng-container *ngFor="let button of config.buttons">
            <shared-ui-button
                [mat-dialog-close]="
                    config.hasInput && button.isInputResult
                        ? field.value
                        : button.result
                "
                [appearance]="button.appearance"
                [color]="button.color"
                [disabled]="
                    config.hasInput && button.isInputResult && !field.value
                "
            >
                {{ button.text }}
            </shared-ui-button>
        </ng-container>
    </mat-dialog-actions>

    <ng-template #textTitle>
        <h2 mat-dialog-title>
            {{ config.title }}
        </h2>
    </ng-template>
    <ng-template #textContent>
        <div>{{ config.content }}</div>
    </ng-template>
</div>
