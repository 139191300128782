import { BaseModel, StrictBaseModelPart, hasUnionKeysStrict } from '@dev-stream/utils';

export class HashForSignResult extends BaseModel<HashForSignResult> {
    protected BASE_TYPE = HashForSignResult;
    'CacheId'!: string;
    'Hash'!: string;

    public GetDefaultValue(): StrictBaseModelPart<HashForSignResult> {
        return {
            CacheId: '',
            Hash: '',
        };
    }

    public static isHashForSignResult(o: any): o is HashForSignResult {
        return o instanceof HashForSignResult || hasUnionKeysStrict(o, ['CacheId', 'Hash']);
    }
}
