import { CertificateIssuerType } from './CertificateIssuerType.model';
import { BaseModel, StrictBaseModelPart, EnumProperty, hasUnionKeys } from '@dev-stream/utils';

export class CertificateInfo extends BaseModel<CertificateInfo> {
    protected BASE_TYPE = CertificateInfo;
    @EnumProperty(() => CertificateIssuerType)
    'IssuerType'!: CertificateIssuerType | null;
    'FirstName'!: string | null;
    'LastName'!: string | null;
    'GivenName'!: string | null;
    'IssuerIIN'!: string | null;
    'CompanyBIN'!: string | null;
    'CompanyName'!: string | null;
    'SignedHash'!: string | null;
    'Tsp'!: string | null;
    'CacheId'!: string | null;
    'HasAccounts'!: boolean | null;

    public GetDefaultValue(): StrictBaseModelPart<CertificateInfo> {
        return {
            IssuerType: null,
            FirstName: null,
            LastName: null,
            GivenName: null,
            IssuerIIN: null,
            CompanyBIN: null,
            CompanyName: null,
            SignedHash: null,
            Tsp: null,
            CacheId: null,
            HasAccounts: null,
        };
    }

    public static isCertificateInfo(o: any): o is CertificateInfo {
        return (
            o instanceof CertificateInfo ||
            hasUnionKeys(o, [
                'IssuerType',
                'FirstName',
                'LastName',
                'GivenName',
                'IssuerIIN',
                'CompanyBIN',
                'CompanyName',
                'SignedHash',
                'Tsp',
                'CacheId',
                'HasAccounts',
            ])
        );
    }
}
