import { BaseModel, StrictBaseModelPart, hasUnionKeys } from '@dev-stream/utils';

export class StringApiSuccessResponseModel extends BaseModel<StringApiSuccessResponseModel> {
    protected BASE_TYPE = StringApiSuccessResponseModel;
    'Header'!: string | null;
    'Description'!: string | null;
    'Payload'!: string | null;

    public GetDefaultValue(): StrictBaseModelPart<StringApiSuccessResponseModel> {
        return {
            Header: null,
            Description: null,
            Payload: null,
        };
    }

    public static isStringApiSuccessResponseModel(o: any): o is StringApiSuccessResponseModel {
        return o instanceof StringApiSuccessResponseModel || hasUnionKeys(o, ['Header', 'Description', 'Payload']);
    }
}
