import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { BladeContainerComponent } from './blade-container/blade-container.component';
import { IconModule } from '@visurel/iconify-angular';
import { ButtonModule } from '../button/button.module';
import { PortalModule } from '@angular/cdk/portal';
import { BladeService } from './blade.service';

@NgModule({
    declarations: [BladeContainerComponent],
    imports: [
        CommonModule,
        OverlayModule,
        IconModule,
        ButtonModule,
        PortalModule,
    ],
    exports: [],
})
export class BladeModule {
    static forRoot(): ModuleWithProviders<BladeRootModule> {
        return { ngModule: BladeRootModule, providers: [BladeService] };
    }
}

@NgModule({
    imports: [BladeModule],
    exports: [BladeModule],
})
export class BladeRootModule {}
