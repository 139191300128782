import {
    mapToApiFailureResponseModel,
    mapToApiFailureResponseModelFromBlobResponse,
    SharedApiFailureResponseModel,
} from '@idocs/api';
import { NotificationConfiguration } from '@idocs/shared-ui/notification';
import { BaseModelPart } from '@dev-stream/utils';

const apiResponseErrorHandlerImpl = (
    err: Error,
    parseErrorAsJson = false
): NotificationConfiguration | null => {
    const error = mapToApiFailureResponseModel(err, parseErrorAsJson);
    return error
        ? new NotificationConfiguration({
              color: 'danger',
              duration: 5000,
              title: error.Header,
              content: error.Description ?? 'Ошибка',
              exception: err,
          })
        : null;
};

/**
 * method to get error for request with responseType: 'blob'
 */
const apiBlobResponseErrorHandlerImpl = async (
    err: Error
): Promise<NotificationConfiguration | null> => {
    const error = await mapToApiFailureResponseModelFromBlobResponse(err);
    return error
        ? new NotificationConfiguration({
              color: 'danger',
              duration: 5000,
              title: error.Header,
              content: error.Description ?? 'Ошибка',
              exception: err,
          })
        : null;
};
export const apiResponseErrorHandler = (err: Error) =>
    apiResponseErrorHandlerImpl(err, false);
export const apiResponseErrorHandlerWithParsedError = (err: Error) =>
    apiResponseErrorHandlerImpl(err, true);
export const apiBlobResponseErrorHandler = (err: Error) =>
    apiBlobResponseErrorHandlerImpl(err);

export function createErrorNotification(
    extendWith?: BaseModelPart<NotificationConfiguration, never>
) {
    return (error: SharedApiFailureResponseModel | null) =>
        error
            ? new NotificationConfiguration(
                  {
                      color: 'danger',
                      duration: 5000,
                      title: error.Header,
                      content: error.Description ?? 'Ошибка',
                      exception: error.Exception,
                  },
                  extendWith ?? {}
              )
            : null;
}
