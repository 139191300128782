import { IAuthenticationChecker } from '@idocskz/identity-connector';
import { Injectable } from '@angular/core';
import { SharedAccountModel } from '@idocs/shared-logic';

@Injectable()
export class IdentityAuthenticationChecker implements IAuthenticationChecker<SharedAccountModel> {
    async check(account: SharedAccountModel): Promise<SharedAccountModel> {
        return account;
    }
}
