import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawerLayoutModule } from '@idocs/shared-ui/drawer-layout';
import { HelpdeskPresentationsPagesNavigationComponent } from './helpdesk-presentations-pages-navigation/helpdesk-presentations-pages-navigation.component';

@NgModule({
    declarations: [HelpdeskPresentationsPagesNavigationComponent],
    imports: [CommonModule, DrawerLayoutModule],
    exports: [HelpdeskPresentationsPagesNavigationComponent],
})
export class HelpdeskPresentationsComponentsModule {}
