import { ConnectedPosition } from '@angular/cdk/overlay';

/**
 * Connected overlay position used when blade displayed on right and has already opened blades on the right side of a page
 */
export const BLADE_OVERLAY_LEFT_POSITION: ConnectedPosition = {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
};

/**
 * Connected overlay position used when blade displayed on left and has already opened blades on the left side of a page
 */
export const BLADE_OVERLAY_RIGHT_POSITION: ConnectedPosition = {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
};
