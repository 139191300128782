import { TemplateRef } from '@angular/core';
import { BaseModel, BaseModelPart, Property, StrictBaseModelPart } from '@dev-stream/utils';
import { ButtonColor, ButtonType } from '@idocs/shared-ui/button';

export class DialogButtonConfiguration<TValue> extends BaseModel<DialogButtonConfiguration<TValue>> {
    GetDefaultValue(): StrictBaseModelPart<DialogButtonConfiguration<TValue>, never> {
        return {
            text: '',
            result: null,
            color: 'accent',
            appearance: 'default',
            isInputResult: false,
        };
    }

    protected BASE_TYPE = DialogButtonConfiguration;
    text!: string;
    result?: TValue | null;
    appearance!: ButtonType;
    color!: ButtonColor;
    isInputResult!: boolean;
}

export class DialogConfiguration<TValue = any> extends BaseModel<DialogConfiguration<TValue>> {
    GetDefaultValue(): StrictBaseModelPart<DialogConfiguration<TValue>, never> {
        return {
            title: null,
            content: null,
            buttons: [],
            hasInput: false,
            inputConfiguration: new PromptDialogInputConfiguration(),
            hasCloseIcon: false,
        };
    }

    protected BASE_TYPE = DialogConfiguration;
    title?: string | TemplateRef<any> | null;
    content?: string | TemplateRef<any> | null;
    hasCloseIcon!: boolean;

    @Property(() => DialogButtonConfiguration)
    buttons!: DialogButtonConfiguration<any>[];
    hasInput!: boolean;
    @Property(() => PromptDialogInputConfiguration)
    inputConfiguration!: PromptDialogInputConfiguration;
    templatesContextData?: TValue;
}

export class ConfirmDialogConfiguration<TValue = any> extends BaseModel<ConfirmDialogConfiguration<TValue>> {
    GetDefaultValue(): StrictBaseModelPart<ConfirmDialogConfiguration<TValue>, never> {
        return {
            title: 'Подтверждение действия',
            content: 'Подтвердите действие',
            cancelBtnText: 'Отменить',
            cancelBtnAppearance: 'outline',
            cancelBtnColor: 'danger',
            acceptBtnText: 'Подтвердить',
            acceptBtnAppearance: 'default',
            acceptBtnColor: 'accent',
            hasCloseIcon: false,
        };
    }

    protected BASE_TYPE = ConfirmDialogConfiguration;
    title?: string | TemplateRef<any> | null;
    content?: string | TemplateRef<any> | null;
    cancelBtnText!: string;
    cancelBtnAppearance!: ButtonType;
    cancelBtnColor!: ButtonColor;
    acceptBtnText!: string;
    acceptBtnAppearance!: ButtonType;
    acceptBtnColor!: ButtonColor;
    templatesContextData?: TValue;
    hasCloseIcon!: boolean;
}

export class PromptDialogInputConfiguration extends BaseModel<PromptDialogInputConfiguration> {
    GetDefaultValue(): StrictBaseModelPart<PromptDialogInputConfiguration, never> {
        return {
            label: 'Prompt label',
            type: 'text',
        };
    }

    protected BASE_TYPE = PromptDialogInputConfiguration;
    label!: string;
    type!: string;
}

export class PromptDialogConfiguration<TValue = any> extends ConfirmDialogConfiguration<TValue> {
    protected BASE_TYPE = PromptDialogConfiguration;

    @Property(() => PromptDialogInputConfiguration)
    inputConfiguration!: PromptDialogInputConfiguration;

    GetDefaultValue(): StrictBaseModelPart<PromptDialogConfiguration<TValue>, never> {
        return {
            ...super.GetDefaultValue(),
            title: 'Prompt title',
            content: 'Prompt content',
            acceptBtnText: 'Ок',
            cancelBtnText: 'Отмена',
            inputConfiguration: new PromptDialogInputConfiguration(),
        };
    }

    constructor(...parts: BaseModelPart<PromptDialogConfiguration<TValue>>[]) {
        super(...parts);
    }
}

export class AlertDialogConfiguration<TValue = any> extends BaseModel<AlertDialogConfiguration<TValue>> {
    GetDefaultValue(): StrictBaseModelPart<AlertDialogConfiguration<TValue>, never> {
        return {
            title: 'Предупреждение',
            content: 'Предупреждение действия',
            btnText: 'Ok',
            btnAppearance: 'default',
            btnColor: 'accent',
            hasCloseIcon: false,
        };
    }

    protected BASE_TYPE = AlertDialogConfiguration;

    title?: string | TemplateRef<any> | null;
    content?: string | TemplateRef<any> | null;
    btnText!: string;
    btnAppearance!: ButtonType;
    btnColor!: ButtonColor;
    templatesContextData?: TValue;
    hasCloseIcon!: boolean;
}
