<div class="flex flex-col flex-1 items-center justify-center">
    <div class="flex items-center justify-between max-w-[100%] w-[1000px] gap-24">
        <div class="code flex flex-col">
            <span
                class="text-accent-main font-header-1 text-[200px] leading-[1.2em] font-bold"
                *ngFor="let char of code.split('')"
                >{{ char }}</span
            >
        </div>
        <div class="info">
            <div class="logo text-accent-main text-[100px] mb-24">
                <ic-icon align="left" width="unset" [icon]="idocsLogo"></ic-icon>
            </div>
            <h4 class="text-header-1 font-header-1 text-on-light-base mb-12">{{ header }}</h4>
            <p class="text-paragraph-2 font-paragraph-2 text-on-light-base w-[600px] max-w-[100%] mb-12">{{ content }}</p>
            <div class="flex items-baseline justify-between">
                <a
                    [href]="homePageConfig.url"
                    *ngIf="!homePageConfig.sameOrigin"
                    class="text-on-light-info flex items-center font-paragraph-2 text-paragraph-2"
                >
                    <ic-icon [icon]="homeIcon" class="mr-8"></ic-icon>
                    {{ homePageConfig.text ?? 'Вернуться на главную' }}
                </a>
                <a
                    [routerLink]="homePageConfig.url"
                    *ngIf="homePageConfig.sameOrigin"
                    class="text-on-light-info flex items-center font-paragraph-2 text-paragraph-2"
                >
                    <ic-icon [icon]="homeIcon" class="mr-8"></ic-icon>
                    {{ homePageConfig.text ?? 'Вернуться на главную' }}</a
                >
                <a
                    href="https://idocs.kz/contacts"
                    target="_blank"
                    class="text-on-light-info flex items-center font-paragraph-2 text-paragraph-2"
                    ><ic-icon [icon]="helpIcon" class="mr-8"></ic-icon> Поддержка</a
                >
            </div>
        </div>
    </div>
</div>
