import { ApplicationUserTwoFactorAuthOption } from './ApplicationUserTwoFactorAuthOption.model';
import { BaseModel, StrictBaseModelPart, Property, EnumProperty, hasUnionKeys } from '@dev-stream/utils';

export class SystemSettingsModel extends BaseModel<SystemSettingsModel> {
    protected BASE_TYPE = SystemSettingsModel;
    @Property(() => Date)
    'PasswordLastUpdatedOn'!: Date | null;
    @Property(() => Date)
    'PasswordExpiresOn'!: Date | null;
    'Email'!: string | null;
    'PhoneNumber'!: string | null;
    'TwoFactorEnabled'!: boolean | null;
    @EnumProperty(() => ApplicationUserTwoFactorAuthOption)
    'TwoFactorOption'!: ApplicationUserTwoFactorAuthOption | null;
    'TwoFactorOptionText'!: string | null;

    public GetDefaultValue(): StrictBaseModelPart<SystemSettingsModel> {
        return {
            PasswordLastUpdatedOn: null,
            PasswordExpiresOn: null,
            Email: null,
            PhoneNumber: null,
            TwoFactorEnabled: null,
            TwoFactorOption: null,
            TwoFactorOptionText: null,
        };
    }

    public static isSystemSettingsModel(o: any): o is SystemSettingsModel {
        return (
            o instanceof SystemSettingsModel ||
            hasUnionKeys(o, [
                'PasswordLastUpdatedOn',
                'PasswordExpiresOn',
                'Email',
                'PhoneNumber',
                'TwoFactorEnabled',
                'TwoFactorOption',
                'TwoFactorOptionText',
            ])
        );
    }
}
