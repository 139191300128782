import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorPagesRoutingModule } from '@idocs/shared-ui/error-pages/error-pages.routing-module';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { AppIconsModule } from '@idocs/shared-ui/app-icons';
import { RouterModule } from '@angular/router';
import { HOME_PAGE_CONFIG, IHomePageConfig } from './tokens';

@NgModule({
    declarations: [ErrorPageComponent],
    imports: [CommonModule, AppIconsModule, RouterModule],
})
export class ErrorPagesModule {
    static forRoot(
        homePageConfig: IHomePageConfig = { url: '/', sameOrigin: true }
    ): ModuleWithProviders<ErrorPagesRootModule> {
        return {
            ngModule: ErrorPagesRootModule,
            providers: [{ provide: HOME_PAGE_CONFIG, useValue: homePageConfig }],
        };
    }
}

@NgModule({
    imports: [CommonModule, ErrorPagesRoutingModule, ErrorPagesModule],
    exports: [ErrorPagesModule],
})
export class ErrorPagesRootModule {}
