import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';
import { ButtonColor, ButtonType } from '@idocs/shared-ui/button';

/**
 * Class to define blade action button appearance and result
 *
 * TODO add type of an action (submit | button) and isHidden function to determine whether to show action or not
 */
export class BladeAction<TResult = any, TComponent = any> extends BaseModel<
    BladeAction<TResult, TComponent>
> {
    GetDefaultValue(): StrictBaseModelPart<BladeAction<TResult, TComponent>> {
        return {
            text: 'DEFAULT TEXT',
            appearance: 'default',
            color: 'accent',
            disabled: () => false,
        };
    }

    protected BASE_TYPE = BladeAction;

    /**
     * Text on action button
     */
    text!: string;
    /**
     * Result which will be returned to the {@link BladeRef.onClose} observable
     * Can be calculated by using delegate function with componentInstance as a parameter
     */
    result?: ((componentInstance: TComponent) => TResult | undefined) | TResult;
    /**
     * Action button appearance
     */
    appearance!: ButtonType;

    disabled!: (componentInstance: TComponent) => boolean;
    tooltip?: (componentInstance: TComponent) => string | null | undefined;

    /**
     * Action button color
     */
    color!: ButtonColor;

    /**
     * Type guard to check if result is calculated
     * @param result Pass {@link BladeAction.result} here
     */
    static isResultCalculated<TComponent, TResult>(
        result: any
    ): result is (componentInstance: TComponent) => TResult | undefined {
        return result && typeof result == 'function';
    }
}
