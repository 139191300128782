import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { share, tap } from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    private cache: Map<string, { response: HttpResponse<any>; cacheUntil: number }> = new Map();

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.method !== 'GET') {
            return next.handle(req);
        }

        if (req.headers.has('no-cache')) {
            return next.handle(req);
        }

        const cachedResponse = this.cache.get(req.urlWithParams);

        if (cachedResponse && new Date(cachedResponse.cacheUntil).getTime() - new Date().getTime() > 0) {
            return of(cachedResponse.response.clone());
        } else {
            return next.handle(req).pipe(
                tap((stateEvent) => {
                    if (req.headers.has('cache-until') && stateEvent instanceof HttpResponse && stateEvent.ok) {
                        const cacheUntil = +(req.headers.get('cache-until') || 0);
                        this.cache.set(req.urlWithParams, {
                            response: stateEvent.clone(),
                            cacheUntil: cacheUntil || new Date().getTime() + 10000,
                        });
                    }
                }),
                share()
            );
        }
    }
}
