import { CertificateIssuerType } from './CertificateIssuerType.model';
import { ExistingAccountInfoModel } from './ExistingAccountInfoModel.model';
import { BaseModel, StrictBaseModelPart, Property, EnumProperty, hasUnionKeys } from '@dev-stream/utils';

export class ValidateSignedHashCommandResult extends BaseModel<ValidateSignedHashCommandResult> {
    protected BASE_TYPE = ValidateSignedHashCommandResult;
    'IssuerFullName'!: string | null;
    @EnumProperty(() => CertificateIssuerType)
    'IssuerType'!: CertificateIssuerType | null;
    'TIN'!: string | null;
    'CompanyName'!: string | null;
    'CacheId'!: string | null;
    'HasAccounts'!: boolean | null;
    @Property(() => ExistingAccountInfoModel)
    'ExistingAccounts'!: Array<ExistingAccountInfoModel | null>;
    'IsGovServiceAvailable'!: boolean | null;

    public GetDefaultValue(): StrictBaseModelPart<ValidateSignedHashCommandResult> {
        return {
            IssuerFullName: null,
            IssuerType: null,
            TIN: null,
            CompanyName: null,
            CacheId: null,
            HasAccounts: null,
            ExistingAccounts: [],
            IsGovServiceAvailable: null,
        };
    }

    public static isValidateSignedHashCommandResult(o: any): o is ValidateSignedHashCommandResult {
        return (
            o instanceof ValidateSignedHashCommandResult ||
            hasUnionKeys(o, [
                'IssuerFullName',
                'IssuerType',
                'TIN',
                'CompanyName',
                'CacheId',
                'HasAccounts',
                'ExistingAccounts',
                'IsGovServiceAvailable',
            ])
        );
    }
}
