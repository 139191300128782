import { NgModule, ModuleWithProviders, InjectionToken, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export const BASE_URL = new InjectionToken('BusinessServerApiModule.BASE_URL');

@NgModule({})
export class BusinessServerApiModule {
    static forRoot(baseUrl: string): ModuleWithProviders<BusinessServerApiRootModule> {
        return {
            providers: [{ provide: BASE_URL, useValue: baseUrl }],
            ngModule: BusinessServerApiRootModule,
        };
    }
}

@NgModule({})
export class BusinessServerApiRootModule {
    constructor(@Optional() http: HttpClient | null) {
        if (!http) {
            throw new Error(`Please import "HttpClientModule" in your application module, before using this api service`);
        }
    }
}
