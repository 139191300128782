import { Component, Injector, OnInit } from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { BannerConfig } from '@idocs/shared-ui/banner';
import { BannerService } from '@idocs/shared-ui/banner/services';
import { ConnectionStatus, NcaLayerService } from 'ng-ncalayer';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'identity-app-root',
    templateUrl: './identity-app.component.html',
    styleUrls: ['./identity-app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
    constructor(
        injector: Injector,
        private ncaLayer: NcaLayerService,
        private banners: BannerService // private router: Router
    ) {
        super(injector);
    }

    private ncaLayerBannerTimer?: NodeJS.Timeout;

    async ngOnInit() {
        this.ncaLayer.connection
            .statusChange()
            .pipe(takeUntil(this.destroyed))
            .subscribe((status) => {
                if (status == ConnectionStatus.Opened) {
                    if (this.ncaLayerBannerTimer) {
                        clearTimeout(this.ncaLayerBannerTimer);
                        delete this.ncaLayerBannerTimer;
                    }
                    this.banners.close('nca-layer-status');
                } else {
                    if (this.ncaLayerBannerTimer) {
                        return;
                    }
                    this.ncaLayerBannerTimer = setTimeout(() => {
                        this.banners.open(
                            new BannerConfig({
                                color: 'danger',
                                id: 'nca-layer-status',
                                text: 'Для работы с сертификатами ЭЦП необходимо запустить приложение NCALayer.',
                            })
                        );
                    }, 1000);
                }
            });
        try {
            await this.ncaLayer.connection.openConnection();
        } catch {
            console.error('Failed to connect to NCALayer');
        }
    }
}
