import { BaseModel, StrictBaseModelPart, hasUnionKeys } from '@dev-stream/utils';

export class GuidApiSuccessResponseModel extends BaseModel<GuidApiSuccessResponseModel> {
    protected BASE_TYPE = GuidApiSuccessResponseModel;
    'Header'!: string | null;
    'Description'!: string | null;
    'Payload'!: string | null;

    public GetDefaultValue(): StrictBaseModelPart<GuidApiSuccessResponseModel> {
        return {
            Header: null,
            Description: null,
            Payload: null,
        };
    }

    public static isGuidApiSuccessResponseModel(o: any): o is GuidApiSuccessResponseModel {
        return o instanceof GuidApiSuccessResponseModel || hasUnionKeys(o, ['Header', 'Description', 'Payload']);
    }
}
