import { ApplicationEnvironment } from './environment.model';
import 'zone.js/plugins/zone-error';
import {
    IdocsServerEnvironment,
    PortalUrls,
} from '@idocs/shared-logic/server-environment'; // Included with Angular CLI.

export const environment = new ApplicationEnvironment({
    env: IdocsServerEnvironment.Refactor,
    apiServerUrl: 'https://api.refactor.idocs.kz',
    identityServerUrl: 'https://identity.refactor.idocs.kz',
    corporateServerUrl: 'https://site.api.refactor.idocs.kz',
    isProductionMode: false,
    froalaApiKey:
        'yDC5hF4E3C11A5E5A4gKTRe1CD1PGb1DESAb1Kd1EBH1Pd1TKoD6C5G5F4G2D3I3B4C5A4==',
    cloudpaymentsPublicKey: 'pk_abf62ff2c3af4e3e7bda871405fc6',
    portalUrls: new PortalUrls({
        cabinet: 'https://cabinet.refactor.idocs.kz',
        billing: 'https://billing.refactor.idocs.kz',
        identity: 'https://account.refactor.idocs.kz',
        sign: 'https://sign.refactor.idocs.kz',
        company: 'https://company.refactor.idocs.kz',
        signWidgetServerUrl: 'https://widget.refactor.idocs.kz'
    }),
});
