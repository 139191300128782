import {
    AfterContentInit,
    Component,
    ContentChild,
    OnInit,
    ViewChild,
} from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { InputDirective } from '@idocs/shared-ui/inputs/input/input.directive';
import { MatFormField } from '@angular/material/form-field';

@Component({
    selector: 'shared-ui-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
})
export class InputComponent
    extends BaseComponent
    implements OnInit, AfterContentInit
{
    @ContentChild(InputDirective)
    input?: InputDirective;

    @ViewChild(MatFormField, { static: true }) matFormField?: MatFormField;

    ngOnInit() {}

    ngAfterContentInit(): void {
        if (!this.input) {
            throw new Error(
                '[sharedUiInput] is mandatory to use InputComponent'
            );
        }

        this.matFormField &&
            this.input.matFormFieldControl &&
            (this.matFormField._control = this.input?.matFormFieldControl);
    }
}
