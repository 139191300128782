import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { RouteBuilder } from 'ng-utils/extensions';
import { IdentityPolicies } from './identity-app.auth-policies';

const routes: Routes = [
    RouteBuilder.withPath('')
        .addChildren(
            RouteBuilder.withPath('').asRedirectRoute('app', 'full').build(),
            RouteBuilder.withPath('app')
                .protectWith(
                    IdentityPolicies.Authenticated,
                    async (router) => await router.navigate(['/account'])
                )
                .withLoadChildren(() =>
                    import(
                        './pages/identity-app-pages/identity-app-pages.module'
                    ).then((m) => m.IdentityAppPagesModule)
                )
                .build(),
            RouteBuilder.withPath('account')
                .withLoadChildren(() =>
                    import('./pages/account-pages/account-pages.module').then(
                        (m) => m.AccountPagesModule
                    )
                )
                .build(),
            RouteBuilder.withPath('authenticate')
                .withLoadChildren(() =>
                    import(
                        './pages/authenticate-pages/authenticate-pages.module'
                    ).then((m) => m.AuthenticateModule)
                )
                .build(),
            RouteBuilder.withPath('not-registered')
                .withLoadChildren(() =>
                    import(
                        './pages/authenticate-not-registered-pages/authenticate-not-registered-pages.module'
                    ).then((m) => m.AuthenticateNotRegisteredPagesModule)
                )
                .build(),
            RouteBuilder.withPath('forgot-password')
                .withLoadChildren(() =>
                    import(
                        './pages/forgot-password-pages/forgot-password-pages.module'
                    ).then((m) => m.ForgotPasswordPagesModule)
                )
                .build(),
            RouteBuilder.withPath('register')
                .withLoadChildren(() =>
                    import('./pages/sign-up-pages/sign-up-pages.module').then(
                        (m) => m.SignUpPagesModule
                    )
                )
                .build(),
            RouteBuilder.withPath('sso')
                .withLoadChildren(() =>
                    import('./pages/sso-pages/sso-pages.module').then(
                        (m) => m.SsoPagesModule
                    )
                )
                .build()
        )
        .build(),
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            anchorScrolling: 'enabled',
            onSameUrlNavigation: 'reload',
            preloadingStrategy: PreloadAllModules,
            // enableTracing: true,
        }),
    ],
    exports: [RouterModule],
})
export class IdentityAppRoutingModule {}
