import {
    Component,
    Inject,
    Injector,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '@idocs/shared-ui/core';
import { DialogConfiguration } from '..';
import close from '@iconify/icons-mdi/close';
import { IconifyIcon } from '@idocs/icons';

@Component({
    selector: 'shared-ui-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent extends BaseComponent implements OnInit {
    @ViewChild('textTitle', { static: true })
    defaultTitleTemplate?: TemplateRef<any>;
    @ViewChild('textContent', { static: true })
    defaultContentTemplate?: TemplateRef<any>;

    public titleTemplate?: TemplateRef<any>;
    public contentTemplate?: TemplateRef<any>;
    field = new FormControl();

    close: IconifyIcon = close;

    constructor(
        injector: Injector,
        @Inject(MAT_DIALOG_DATA) public config: DialogConfiguration
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.titleTemplate =
            this.config.title instanceof TemplateRef
                ? this.config.title
                : this.defaultTitleTemplate;
        this.contentTemplate =
            this.config.content instanceof TemplateRef
                ? this.config.content
                : this.defaultContentTemplate;
    }
}
