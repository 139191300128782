import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { InputLabelDirective } from './input/input-label.directive';
import { InputHintDirective } from './input/input-hint.directive';
import { InputErrorDirective } from './input/input-error.directive';
import { NgxMaskModule } from 'ngx-mask';
import { InputDirective } from './input/input.directive';
import { MdcInputComponent } from './mdc-input/mdc-input.component';

@NgModule({
    declarations: [
        InputComponent,
        InputLabelDirective,
        InputHintDirective,
        InputErrorDirective,
        InputDirective,
        MdcInputComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatFormFieldModule,
        NgxMaskModule,
    ],
    exports: [
        InputComponent,
        MdcInputComponent,
        FormsModule,
        ReactiveFormsModule,
        InputLabelDirective,
        InputHintDirective,
        InputErrorDirective,
        InputDirective,
        MatInputModule,
        MatSelectModule,
        NgxMaskModule,
    ],
})
export class InputsModule {}
