import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IdentityAppRoutingModule } from './identity-app-routing.module';
import { AppComponent } from './identity-app.component';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
    CacheInterceptor,
    SharedAccountModel,
    SharedAuthModule,
    SharedLogicModule,
} from '@idocs/shared-logic';
import { NgAuthorizeModule } from 'ng-authorize';
import { IDENTITY_POLICIES } from './identity-app.auth-policies';
import { NgNcalayerModule } from 'ng-ncalayer';
import { BannerModule } from '@idocs/shared-ui/banner';
import { TokenObtainer } from './services/token.obtainer';
import { NotificationModule } from '@idocs/shared-ui/notification';
import { IdentityConnectorModule } from '@idocskz/identity-connector';
import { AppIconsModule } from '@idocs/shared-ui/app-icons';
import { IdentityServerApiModule } from 'libraries/api/src/identity/services/IdentityServerApi.module';
import { CorporateServerApiModule } from 'libraries/api/src/corporate/services/CorporateServerApi.module';
import { NgxMaskModule } from 'ngx-mask';
import { IdentityAuthenticationChecker } from './services/identity.authentication-handler';
import { ErrorPagesModule } from '@idocs/shared-ui/error-pages';
import { BusinessServerApiModule } from '@idocs/api/business/services/BusinessServerApi.module';
import { BladeModule } from '@idocs/shared-ui/blade';
import { AuthService } from './services/auth.service';
import { MatMenuModule } from '@angular/material/menu';
import { HelpdeskPresentationsModule } from '@idocs/shared/helpdesk-presentations';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IdentityAppRoutingModule,
        IdentityConnectorModule.server({
            accountClass: () => SharedAccountModel,
            urls: {
                notRegisteredLoginUrl: '/not-registered',
                loginUrl: '/authenticate',
                registerUrl: '/register',
                accountListUrl: '/account',
            },
            authenticationChecker: IdentityAuthenticationChecker,
        }),
        BusinessServerApiModule.forRoot(`${environment.apiServerUrl}`),
        NgxMaskModule.forRoot(),
        HttpClientModule,
        NotificationModule.forRoot(),
        SharedLogicModule.forRoot(),
        IdentityServerApiModule.forRoot(`${environment.identityServerUrl}`),
        CorporateServerApiModule.forRoot(`${environment.corporateServerUrl}`),
        BusinessServerApiModule.forRoot(`${environment.apiServerUrl}`),
        NgAuthorizeModule.forRoot(IDENTITY_POLICIES),
        AppIconsModule.forRoot({}),
        BannerModule,
        NgNcalayerModule.forRoot(),
        ErrorPagesModule.forRoot({
            url: environment.portalUrls.cabinet,
            sameOrigin: false,
        }),
        BladeModule.forRoot(),
        SharedAuthModule.forRoot({
            tokenObtainer: TokenObtainer,
            authService: AuthService,
        }),
        MatMenuModule,
        HelpdeskPresentationsModule.forRoot({
            signWidgetIframeUrl: environment.portalUrls.signWidgetServerUrl,
            serverEnvironment: environment.env,
        }),
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        {
            provide: LOCALE_ID,
            useValue: 'ru-RU',
        },
    ],
})
export class IdentityModule {}
