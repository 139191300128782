import { CertificateIssuerType } from './CertificateIssuerType.model';
import { AccountType } from './AccountType.model';
import { BaseModel, StrictBaseModelPart, EnumProperty, hasUnionKeys } from '@dev-stream/utils';

export class AccountInfo extends BaseModel<AccountInfo> {
    protected BASE_TYPE = AccountInfo;
    'Id'!: string | null;
    'Email'!: string | null;
    'PhoneNumber'!: string | null;
    'ApplicationUserId'!: string | null;
    'CompanyName'!: string | null;
    'CompanyBIN'!: string | null;
    'CompanyId'!: string | null;
    'Position'!: string | null;
    'FirstName'!: string | null;
    'LastName'!: string | null;
    'GivenName'!: string | null;
    'IIN'!: string | null;
    @EnumProperty(() => CertificateIssuerType)
    'IssuerType'!: CertificateIssuerType | null;
    'IsDefault'!: boolean | null;
    'IsCurrent'!: boolean | null;
    'IsValid'!: boolean | null;
    'IsBlocked'!: boolean | null;
    'ImageBase64'!: string | null;
    'RoleName'!: string | null;
    'PositionName'!: string | null;
    'OrgUnitName'!: string | null;
    @EnumProperty(() => AccountType)
    'AccountType'!: AccountType | null;
    'RedirectUrl'!: string | null;

    public GetDefaultValue(): StrictBaseModelPart<AccountInfo> {
        return {
            Id: null,
            Email: null,
            PhoneNumber: null,
            ApplicationUserId: null,
            CompanyName: null,
            CompanyBIN: null,
            CompanyId: null,
            Position: null,
            FirstName: null,
            LastName: null,
            GivenName: null,
            IIN: null,
            IssuerType: null,
            IsDefault: null,
            IsCurrent: null,
            IsValid: null,
            IsBlocked: null,
            ImageBase64: null,
            RoleName: null,
            PositionName: null,
            OrgUnitName: null,
            AccountType: null,
            RedirectUrl: null,
        };
    }

    public static isAccountInfo(o: any): o is AccountInfo {
        return (
            o instanceof AccountInfo ||
            hasUnionKeys(o, [
                'Id',
                'Email',
                'PhoneNumber',
                'ApplicationUserId',
                'CompanyName',
                'CompanyBIN',
                'CompanyId',
                'Position',
                'FirstName',
                'LastName',
                'GivenName',
                'IIN',
                'IssuerType',
                'IsDefault',
                'IsCurrent',
                'IsValid',
                'IsBlocked',
                'ImageBase64',
                'RoleName',
                'PositionName',
                'OrgUnitName',
                'AccountType',
                'RedirectUrl',
            ])
        );
    }
}
