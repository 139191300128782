import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    HostBinding,
    Injector,
} from '@angular/core';
import { BaseComponent } from '@idocs/shared-ui/core';
import { AppColor, ColorMap } from './../../../models';

@Component({
    selector: 'shared-ui-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('banner', [
            state(
                'opened',
                style({
                    height: '*',
                })
            ),
            transition('void => opened', [
                style({ height: 0 }),
                animate('300ms ease'),
            ]),
            transition('* => void', [
                style({ height: '*' }),
                animate('300ms ease', style({ height: 0 })),
            ]),
        ]),
    ],
})
export class BannerComponent extends BaseComponent implements OnInit {
    @Input() color: AppColor = 'accent';

    @HostBinding('@banner')
    state = 'opened';

    contentClamped = true;

    classMap: ColorMap<string[]> = {
        accent: ['bg-accent-main', 'text-white'],
        danger: ['bg-danger-main', 'text-white'],
        info: ['bg-info-main', 'text-white'],
        neutral: ['bg-neutral-main', 'text-white'],
        primary: ['bg-primary-main', 'text-white'],
        secondary: ['bg-secondary-main', 'text-white'],
        success: ['bg-success-main', 'text-primary-main'],
        warning: ['bg-warning-main', 'text-primary-main'],
        default: ['bg-white', 'text-primary-main'],
        anyColor: [],
    };

    @HostBinding('class')
    get hostClasses() {
        return [
            ...(this.classMap.anyColor || []),
            ...(this.classMap[this.color] || this.classMap.default),
        ].join(' ');
    }

    constructor(injector: Injector) {
        super(injector);
        this.displayContents = false;
    }

    ngOnInit(): void {}
}
