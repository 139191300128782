import { BaseModel, StrictBaseModelPart } from '@dev-stream/utils';
import { OverlayConfig } from '@angular/cdk/overlay';
import { v4 as generateGuid } from 'uuid';

export class BaseBladeConfig extends BaseModel<BaseBladeConfig> {
    protected BASE_TYPE = BaseBladeConfig;
    /**
     * Title of the blade
     */
    title?: string | null;
    /**
     * Flag which defines if backdrop will be added to blade overlay
     */
    hasBackdrop!: boolean;
    /**
     * if {@link hasBackdrop} is `true` defines if the click on it should close the blade. NOTE: the blade result in this case will be `undefined`
     */
    closeOnBackdropClick!: boolean;
    /**
     * Flag which defines if close icon should be present in upper-right corner of the blade
     */
    showCloseButton!: boolean;
    /**
     * Flag defines if blade will dispose if navigation to another page happens
     */
    disposeOnNavigation!: boolean;

    /**
     * List of classes which should be added to blade container div
     */
    panelClasses!: string[];
    /**
     * Parent blade {@link id}
     */
    parent?: string;
    /**
     * Unique identifier used for building hierarchy of a blades
     */
    id!: string;
    /**
     * Side of a page where blade should be opened, on mobile screens make no difference as blade is resized to use whole screen width
     */
    side!: 'left' | 'right';

    /**
     * Blade overlay configuration
     */
    overlayConfig?: OverlayConfig;

    GetDefaultValue(): StrictBaseModelPart<BaseBladeConfig, never> {
        return {
            closeOnBackdropClick: true,
            disposeOnNavigation: true,
            hasBackdrop: true,
            showCloseButton: true,
            panelClasses: [],
            id: generateGuid(),
            side: 'left',
        };
    }
}
