import { ModuleWithProviders, NgModule } from '@angular/core';
@NgModule({
    declarations: [],
    imports: [],
    exports: [],
})
export class SharedLogicModule {
    static forRoot(): ModuleWithProviders<SharedLogicRootModule> {
        return {
            ngModule: SharedLogicRootModule,
        };
    }
}
@NgModule({
    imports: [SharedLogicModule],
    exports: [SharedLogicModule],
})
export class SharedLogicRootModule {}
