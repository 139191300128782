import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export abstract class Disposable implements OnDestroy {
    private destroySubject = new Subject();
    protected destroyed = this.destroySubject.asObservable();

    ngOnDestroy(): void {
        this.destroySubject.next();
        this.destroySubject.complete();
    }
}
